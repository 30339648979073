<template>
  <div>
    <template
      v-if="row.sw030s14===false"
    >
      <b-row>
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
          >
            <b-form-group
              :label="$t('Data do evento')"
            >
              <date-picker
                v-model="sw030s04"
                value-type="format"
                :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                type="date"
                :editable="false"
                :clearable="true"
                :lang="langCalendar"
                :append-to-body="true"
                prefix-class="sw"
              >
                <template #icon-clear>
                  <v-select-deselect />
                </template>
              </date-picker>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row
        v-if="formVars.typeSend.length > 0"
      >
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
          >
            <b-form-group
              :label="$t('Todo o dia ou hora?')"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <v-select
                v-model="sw030s18"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="formVars.typeSend"
                append-to-body
                :calculate-position="withPopper"
                :clearable="true"
                label="txt"
                item-text="txt"
                item-value="id"
                @input="chooseAllDayOrHour"
              >
                <template #option="{ txt }">
                  {{ txt }}
                </template>
                <div slot="no-options">
                  {{ $t('Sem resultados') }}
                </div>
              </v-select>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row
        v-if="sw030s04_time_show===true"
      >
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
          >
            <b-form-group
              :label="$t('Hora do evento')"
            >
              <b-form-timepicker
                v-model="sw030s04_time"
                placeholder="00:00"
                hide-header
                now-button
                :label-close-button="$t('Fechar')"
                :label-now-button="$t('Hora atual')"
                :class="errors.length > 0 ? 'is-invalid':null"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </template>

    <template v-else>
      <template
        v-if="Number(row.sw030s15)===4"
      >
        <b-row>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-group
                :label="$t('Dia do mês')"
              >
                <date-picker
                  v-model="sw030s04"
                  value-type="format"
                  :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                  type="date"
                  :editable="false"
                  :clearable="true"
                  :lang="langCalendar"
                  :append-to-body="true"
                  prefix-class="sw"
                >
                  <template #icon-clear>
                    <v-select-deselect />
                  </template>
                </date-picker>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-group
                :label="$t('Data do evento')"
              >
                <date-picker
                  v-model="sw030s04"
                  value-type="format"
                  :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                  type="date"
                  :editable="false"
                  :clearable="true"
                  :lang="langCalendar"
                  :append-to-body="true"
                  prefix-class="sw"
                >
                  <template #icon-clear>
                    <v-select-deselect />
                  </template>
                </date-picker>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </template>
      <b-row
        v-if="formVars.typeSend.length > 0"
      >
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
          >
            <b-form-group
              :label="$t('Todo o dia ou hora?')"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <v-select
                v-model="sw030s18"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="formVars.typeSend"
                append-to-body
                :calculate-position="withPopper"
                :clearable="true"
                label="txt"
                item-text="txt"
                item-value="id"
                @input="chooseAllDayOrHour"
              >
                <template #option="{ txt }">
                  {{ txt }}
                </template>
                <div slot="no-options">
                  {{ $t('Sem resultados') }}
                </div>
              </v-select>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row
        v-if="sw030s04_time_show===true"
      >
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
          >
            <b-form-group
              :label="$t('Hora do evento')"
            >
              <b-form-timepicker
                v-model="sw030s04_time"
                placeholder="00:00"
                hide-header
                now-button
                :label-close-button="$t('Fechar')"
                :label-now-button="$t('Hora atual')"
                :class="errors.length > 0 ? 'is-invalid':null"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </template>

    <modal-confirm-change-date
      v-if="showModalConfirmChangeDate===true"
      ref="modalConfirmChangeDate"
      :data="modalConfirmChangeDateData"
      :resolve="modalConfirmChangeDateResolve"
    />

  </div>
</template>

<script>
import {
  BFormGroup, BRow, BCol, BFormTimepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import { mixFormGeral } from '../../mixins'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BFormTimepicker,
    vSelect,
    ValidationProvider,
    DatePicker: oDatepicker,
    vSelectDeselect: vSelect.components.Deselect,
    ModalConfirmChangeDate: () => import(/* webpackChunkName: "crm-leads-seguimento-form-edit-date-modal-confirm-change" */ /* webpackPrefetch: true */ './modal/confirmChangeDate.vue'),
  },
  mixins: [mixFormGeral],
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      langCalendar: {},
      sw030s04: null,
      sw030s18: null,
      sw030s04_time: null,
      sw030s04_time_show: false,

      showModalConfirmChangeDate: false,
      modalConfirmChangeDateData: null,
      modalConfirmChangeDateResolve: null,
    }
  },
  computed:
  {
    ...mapGetters('seguimentos', ['formVars']),
  },
  async mounted() {
    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
    })

    if ('sw030s18' in this.row) {
      this.sw030s18 = this.formVars.typeSend.find(o => Number(o.id) === Number(this.row.sw030s18))
      this.chooseAllDayOrHour()
    }

    if ('sw030s04' in this.row) {
      const [date, time] = this.row.sw030s04.split(' ')

      this.sw030s04 = date || null

      if (time !== '00:00:00') {
        this.sw030s04_time = time.substr(0, 5)
      }
    }
  },
  methods: {
    clear() {
      this.sw030s04 = null
      this.sw030s18 = null
      this.sw030s04_time = null
      this.sw030s04_time_show = false
    },
    getData() {
      return {
        sw030s04: this.sw030s04,
        sw030s18: (('id' in this.sw030s18) ? this.sw030s18.id : 0),
        sw030s04_time: ((this.sw030s04_time) ? this.sw030s04_time : '00:00:00'),
      }
    },
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    chooseAllDayOrHour() {
      if ((this.sw030s18 !== null) && ('id' in this.sw030s18) && (Number(this.sw030s18.id) === 0)) {
        this.sw030s04_time = null
        this.sw030s04_time_show = true
      } else {
        this.sw030s04_time = null
        this.sw030s04_time_show = false
      }
    },
    openModalConfirmChangeDate(data, resolve) {
      this.showModalConfirmChangeDate = true
      this.modalConfirmChangeDateData = data
      this.modalConfirmChangeDateResolve = resolve
    },
    closeModalConfirmChangeDate() {
      this.showModalConfirmChangeDate = false
      this.modalConfirmChangeDateData = null
      this.modalConfirmChangeDateResolve = null
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.row.sw030s14===false)?[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Data do evento')}},[_c('date-picker',{attrs:{"value-type":"format","input-class":{'form-control' : true, 'is-invalid': errors.length > 0 },"type":"date","editable":false,"clearable":true,"lang":_vm.langCalendar,"append-to-body":true,"prefix-class":"sw"},scopedSlots:_vm._u([{key:"icon-clear",fn:function(){return [_c('v-select-deselect')]},proxy:true}],null,true),model:{value:(_vm.sw030s04),callback:function ($$v) {_vm.sw030s04=$$v},expression:"sw030s04"}})],1)]}}],null,false,3636609166)})],1)],1),(_vm.formVars.typeSend.length > 0)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Todo o dia ou hora?')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.formVars.typeSend,"append-to-body":"","calculate-position":_vm.withPopper,"clearable":true,"label":"txt","item-text":"txt","item-value":"id"},on:{"input":_vm.chooseAllDayOrHour},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var txt = ref.txt;
return [_vm._v(" "+_vm._s(txt)+" ")]}}],null,true),model:{value:(_vm.sw030s18),callback:function ($$v) {_vm.sw030s18=$$v},expression:"sw030s18"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,false,1664866443)})],1)],1):_vm._e(),(_vm.sw030s04_time_show===true)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Hora do evento')}},[_c('b-form-timepicker',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"00:00","hide-header":"","now-button":"","label-close-button":_vm.$t('Fechar'),"label-now-button":_vm.$t('Hora atual')},model:{value:(_vm.sw030s04_time),callback:function ($$v) {_vm.sw030s04_time=$$v},expression:"sw030s04_time"}})],1)]}}],null,false,3617389786)})],1)],1):_vm._e()]:[(Number(_vm.row.sw030s15)===4)?[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Dia do mês')}},[_c('date-picker',{attrs:{"value-type":"format","input-class":{'form-control' : true, 'is-invalid': errors.length > 0 },"type":"date","editable":false,"clearable":true,"lang":_vm.langCalendar,"append-to-body":true,"prefix-class":"sw"},scopedSlots:_vm._u([{key:"icon-clear",fn:function(){return [_c('v-select-deselect')]},proxy:true}],null,true),model:{value:(_vm.sw030s04),callback:function ($$v) {_vm.sw030s04=$$v},expression:"sw030s04"}})],1)]}}],null,false,177417285)})],1)],1)]:[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Data do evento')}},[_c('date-picker',{attrs:{"value-type":"format","input-class":{'form-control' : true, 'is-invalid': errors.length > 0 },"type":"date","editable":false,"clearable":true,"lang":_vm.langCalendar,"append-to-body":true,"prefix-class":"sw"},scopedSlots:_vm._u([{key:"icon-clear",fn:function(){return [_c('v-select-deselect')]},proxy:true}],null,true),model:{value:(_vm.sw030s04),callback:function ($$v) {_vm.sw030s04=$$v},expression:"sw030s04"}})],1)]}}])})],1)],1)],(_vm.formVars.typeSend.length > 0)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Todo o dia ou hora?')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.formVars.typeSend,"append-to-body":"","calculate-position":_vm.withPopper,"clearable":true,"label":"txt","item-text":"txt","item-value":"id"},on:{"input":_vm.chooseAllDayOrHour},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var txt = ref.txt;
return [_vm._v(" "+_vm._s(txt)+" ")]}}],null,true),model:{value:(_vm.sw030s18),callback:function ($$v) {_vm.sw030s18=$$v},expression:"sw030s18"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,false,1664866443)})],1)],1):_vm._e(),(_vm.sw030s04_time_show===true)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Hora do evento')}},[_c('b-form-timepicker',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"00:00","hide-header":"","now-button":"","label-close-button":_vm.$t('Fechar'),"label-now-button":_vm.$t('Hora atual')},model:{value:(_vm.sw030s04_time),callback:function ($$v) {_vm.sw030s04_time=$$v},expression:"sw030s04_time"}})],1)]}}],null,false,3617389786)})],1)],1):_vm._e()],(_vm.showModalConfirmChangeDate===true)?_c('modal-confirm-change-date',{ref:"modalConfirmChangeDate",attrs:{"data":_vm.modalConfirmChangeDateData,"resolve":_vm.modalConfirmChangeDateResolve}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }